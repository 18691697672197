import * as React from "react";
import styled from 'styled-components';

import SelectSearch from "react-select-search";
import SelectSearchStyle from "react-select-search/style.css";

import * as Axios from "../../../helper/axios";

export default class Search extends React.Component {

	state = {LandKreise: []};

	constructor(props) {
		super(props);
		this.getLandKreise();
	}

	getLandKreise = () => {
		Axios.get('/api/php_LandKreise.php')
			.then(response => this.setState({LandKreise: response.data}))
			.catch(error => console.error(error))
	};


	render() {

		let landkreise = this.state.LandKreise.map(landkreis => ({name: landkreis['Name'], value: landkreis['ID']}));

		return (
			<SelectSearch
				style={{float: "none"}}
				options={ landkreise.length ? landkreise : [{label: "", value: 0}]}
				placeholder="Wähle ein Landkreis aus"/>
		)
	}
}