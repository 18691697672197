import * as React from "react";
import styled from 'styled-components';

import SelectSearch from "react-select-search";
import SelectSearchStyle from "react-select-search/style.css";

import {Orte} from "../mock/Orte";
import * as Axios from "../../../helper/axios";

export default class Musikrichtung extends React.Component {


	state = {Orte: []};

	constructor(props) {
		super(props);
		this.getOrte();
	}

	getOrte = () => {
		Axios.get('/api/php_Orte.php')
			.then(response => this.setState({Orte: response.data}))
			.catch(error => console.error(error))
	};


	render() {

		let Orte = this.state.Orte.map(Ort => ({name: Ort['Name'], value: Ort['ID']}));

		return (
			<SelectSearch
				options={ Orte.length ? Orte : [{label: "", value: 0}]}
				placeholder="Wähle einen Ort"/>
		)
	}
}