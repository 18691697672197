import * as React from "react";
import styled from 'styled-components';

import SelectSearch from "react-select-search";
import SelectSearchStyle from "react-select-search/style.css";

import {Art} from "../mock/Art";
import * as Axios from "../../../helper/axios";

export default class Musikrichtung extends React.Component {

	state = {Veranstaltungsarten: []};

	constructor(props) {
		super(props);
		this.getVeranstaltungsarten();
	}

	getVeranstaltungsarten = () => {
		Axios.get('/api/php_Veranstaltungsarten.php')
			.then(response => this.setState({Veranstaltungsarten: response.data}))
			.catch(error => console.error(error))
	};


	render() {

		let veranstaltungsarten = this.state.Veranstaltungsarten.map(veranstatlungsart => ({name: veranstatlungsart['Name'], value: veranstatlungsart['ID']}));

		return (
			<SelectSearch
				options={ veranstaltungsarten.length ? veranstaltungsarten : [{label: "", value: 0}]}
				placeholder="Wähle einen Club"/>
		)
	}
}