import * as React from "react";
import * as Axios from "../../helper/axios";
import PureTable from "./PureTable";

export default class Development extends React.Component {


	state = {
		DBVersion: [],
		LandKreise: [],
		Orte: [],
		Adressen: [],
		Musikrichtungen: [],
		Veranstaltungsarten: [],
		Veranstaltungen: []
	};

	constructor(props){
		super(props);

		this.getDBVersion();
		this.getLandKreise();
		this.getOrte();
		this.getAdressen();
		this.getMusikrichtungen();
		this.getVeranstaltungsarten();
		this.getVeranstaltungen();
	}

	getDBVersion = () => {
		Axios.get( '/api/php_DBVersion.php')
			.then( response => this.setState({DBVersion: response.data}))
			.catch( error => console.error(error))
	};

	getLandKreise = () => {
		Axios.get( '/api/php_LandKreise.php')
			.then( response => this.setState({LandKreise: response.data}))
			.catch( error => console.error(error))
	};

	getOrte = () => {
		Axios.get( '/api/php_Orte.php')
			.then( response => this.setState({Orte: response.data}))
			.catch( error => console.error(error))
	};

	getAdressen = () => {
		Axios.get( '/api/php_Adressen.php')
			.then( response => this.setState({Adressen: response.data}))
			.catch( error => console.error(error))
	};

	getMusikrichtungen = () => {
		Axios.get( '/api/php_Musikrichtungen.php')
			.then( response => this.setState({Musikrichtungen: response.data}))
			.catch( error => console.error(error))
	};

	getVeranstaltungsarten = () => {
		Axios.get( '/api/php_Veranstaltungsarten.php')
			.then( response => this.setState({Veranstaltungsarten: response.data}))
			.catch( error => console.error(error))
	};

	getVeranstaltungen = () => {
		Axios.get( '/api/php_Veranstaltungen.php')
			.then( response => this.setState({Veranstaltungen: response.data}))
			.catch( error => console.error(error))
	};

	render(){
		return(
			<div>
				<h1>Development Page</h1>

				<h2>DB Version</h2>
				<PureTable data={this.state.DBVersion} />

				<h2>LandKreise</h2>
				<PureTable data={this.state.LandKreise} />

				<h2>Orte</h2>
				<PureTable data={this.state.Orte} />

				<h2>Adressen</h2>
				<PureTable data={this.state.Adressen} />

				<h2>Musikrichtungen</h2>
				<PureTable data={this.state.Musikrichtungen} />

				<h2>Veranstaltungsarten</h2>
				<PureTable data={this.state.Veranstaltungsarten} />

				<h2>Veranstatlungen</h2>
				<PureTable data={this.state.Veranstaltungen} />


			</div>
		)
	}
};