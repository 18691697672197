import axios from 'axios';

const getServerAddress = () => {

	let mode = "dev";

	switch(mode){
		case "dev":
			return "https://schwarze-lausitz.super-dupa-pinky.de";

		default:
			return "";
	}
};

export const get = requestURL => axios.get( getServerAddress() + requestURL);