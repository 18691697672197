import React from 'react'

import App from "./App";
import {Router, Route, Switch} from 'react-router';
import History from './BrowserHistoryAddon';

import Search from "./SubPages/Search/Search";
import CreateEvents from "./SubPages/CreateEvents/CreateEvents";
import Intro from "./SubPages/Intro/Intro";
import Development from "./SubPages/Development/Development";


function RouterRules() {

        return (
            <div>
                <Router history={History}>
                    <Switch>
                        <Route path={'/search'} component={()=><Search/>}/>
                        <Route path={'/create'} component={()=><CreateEvents/>}/>
                        <Route path={'/dev'} component={()=><Development/>}/>
                        <Route component={()=><Intro/>}/>
                    </Switch>
                </Router>
            </div>
        )
}
export default RouterRules;