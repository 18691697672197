import * as React from "react";

import {NavLink} from "react-router-dom";

const LinkBullet = ({link, description}) => <li><NavLink to={link}>{link} <i>{description}</i></NavLink></li>;

export default class Intro extends React.Component {

	render(){
		return (
			<React.Fragment>
				<h1>Herzlich Willkommen!</h1>
				<p>Hier könnte Deine Werbunge stehen. (*Scherz*)</p>
				<p>Die Seite kann mit Unterverzeichnissen umgehen. Derzeit stehen folgende zur Verfügung:</p>
				<ul>
					<LinkBullet link={"/"} description={"(Intro-Seite / diese)"} />
					<LinkBullet link={"/search"} description={"(Such-Seite für den normalen Gast)"} />
					<LinkBullet link={"/create"} description={"(Ersteller-Seite zum Erstellen von neuen Events)"} />
					<br />
					<LinkBullet link={"/dev"} description={"(Entwickler-Seite)"} />
				</ul>
			</React.Fragment>
		)
	}
}