import * as React from "react";
import styled from 'styled-components';

import SelectSearch from "react-select-search";
import SelectSearchStyle from "react-select-search/style.css";

import {ClubLocation} from "../mock/ClubLocation";
import * as Axios from "../../../helper/axios";

export default class Adresse extends React.Component {

	state = {Adressen: []};

	constructor(props) {
		super(props);
		this.getAdressen();
	}

	getAdressen = () => {
		Axios.get('/api/php_Adressen.php')
			.then(response => this.setState({Adressen: response.data}))
			.catch(error => console.error(error))
	};


	render() {

		if( this.state.Adressen.length >= 1 ) {
			let adressen = this.state.Adressen.map(adresse => ({name: adresse['Name'], value: adresse['ID']}));
			return (
				<SelectSearch
					options={adressen.length ? adressen : [{label: "", value: 0}]}
					placeholder="Wähle eine Adresse"/>
			)
		}
		return <div></div>
	}
}