import * as React from "react";
import styled from 'styled-components';


const Table = styled.table``;
const Row = styled.tr`
	padding-top: 3px;
	padding-bottom: 3px;
	background-color: ${ props => props.number%2 ? "transparent;" : "rgba(0,0,0,.1);"}
`;
const RowIndex = styled.td`
	padding-left: 3px;
	padding-right: 3px;
	font-size: 10px;
`;
const Column = styled.td`
	padding-left: 5px;
	padding-right: 5px;
	font-size: 14px;
`;
const Header = styled.th`
	padding-left: 8px;
	padding-right: 8px;
	font-size: 16px;
	font-weight: bold;
`;

const PureTable = (props) =>
	props.data.length > 0 &&
		<Table>
			<Row>
				<Header/>
				{
					props.data.length && Object.keys(props.data[0])
						.filter(columnName => !Number.isInteger(parseInt(columnName)))
						.map((columnName, columnIndex) => <Header>{columnName}</Header>)

				}
			</Row>
			<React.Fragment>{
				props.data
					.map((row, rowIndex) =>
						<Row key={rowIndex} number={rowIndex}>
							<RowIndex>{rowIndex}</RowIndex>
							{Object.keys(row)
								.filter(columnName => !Number.isInteger(parseInt(columnName)))
								.map((columnName, columnIndex) =>
									<Column>{row[columnName]}</Column>)

							}
						</Row>
					)
			}
			</React.Fragment>
		</Table>;

export default PureTable;