import * as React from "react";
import styled from 'styled-components';

import LandKreise from "./SearchElements/Landkreise";
import MusikRichtung from "./SearchElements/Musikrichtung";
import Orte from "./SearchElements/Orte";
import Adresse from "./SearchElements/Adresse";
import Art from "./SearchElements/Art";
import Zeitraum from "./SearchElements/Zeitraum";

const SearchBar = styled.div`
	>* {float: left}
`;

export default class Search extends React.Component {

    render(){
        const SearchContainer = styled.div``;

        return(
            <SearchContainer>
	            <h1>Veranstaltungssuche:</h1>

	            <SearchBar>
		            <LandKreise/>
		            <MusikRichtung />
		            <Orte />
		            <Adresse />
		            <Art />
		            <Zeitraum />
	            </SearchBar>
            </SearchContainer>
        )
    }
}