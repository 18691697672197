import * as React from "react";
import styled from 'styled-components';

import SelectSearch from "react-select-search";
import SelectSearchStyle from "react-select-search/style.css";

import {MusikRichtung} from "../mock/MusikRichtung";
import * as Axios from "../../../helper/axios";

export default class Musikrichtung extends React.Component {

	state = {MusikRichtungen: []};

	constructor(props) {
		super(props);
		this.getMusikRichtungen();
	}

	getMusikRichtungen = () => {
		Axios.get('/api/php_Musikrichtungen.php')
			.then(response => this.setState({MusikRichtungen: response.data}))
			.catch(error => console.error(error))
	};


	render() {

		let MusikRichtungen = this.state.MusikRichtungen.map(musikrichtung => ({name: musikrichtung['Name'], value: musikrichtung['ID']}));

		return (
			<SelectSearch
				options={ MusikRichtungen.length ? MusikRichtungen : [{label: "", value: 0}]}
				placeholder="Wähle eine Musikrichtung"
			/>
		)
	}
}