import * as React from "react";
import styled from 'styled-components';


import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';

import moment from 'moment';
import 'moment/locale/de';

const Container = styled.div`
	height: 50px;
	min-width: 250px;
	
    margin-bottom: 20px;
    background: #fff;
    border-radius: 4px;
    
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
`;
const ResultContainer = styled.div`
	height: 50px;
	min-width: 250px;
	text-align: center;
	line-height: 50px;
	
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    
    font-size: 16px;
    font-weight: 400;
`;

export default class Zeitraum extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			startDate: moment(),
			endDate: moment().add(14, "day")
		};
	}

	onApply = (event, daterange) => {
		this.setState({
			startDate: daterange.startDate,
			endDate: daterange.endDate
		});
	};

	formatDate = (date) => {
		let timestamp = new Date(date),
			month = '' + (timestamp.getMonth() + 1),
			day = '' + timestamp.getDate(),
			year = timestamp.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [month, day, year].join('/');
	};


	render() {

		return(
			<Container>
				<DateRangePicker
					startDate={this.state.startDate}
					endDate={this.state.endDate}
					onApply={this.onApply}
				>
					<ResultContainer>
						{ moment(this.state.startDate).format('l') + " - " + moment(this.state.endDate).format('l') }
					</ResultContainer>
				</DateRangePicker>
			</Container>
		);
	}
};